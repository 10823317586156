import { lazy } from 'react';

import type { RoutesConfig, WrapRoutes } from '@virtuslab/nfs-shared/src/services/routes';

import { ProjectRoutes } from '../../../config/paths';

const DirectReportingPage = lazy(async () => import('.'));

const config: RoutesConfig<WrapRoutes<typeof ProjectRoutes>> = {
  paths: {
    [ProjectRoutes.PROJECTS]: {
      render: DirectReportingPage,
    },
  },
};

export default config;
