import merge from 'lodash/fp/merge';
import set from 'lodash/fp/set';

import {
  AdditionalPeopleNumber,
  BenefitBuyerType,
  BonusType,
  AbsenceTypeInput,
  B2BContractType,
} from '@virtuslab/nfs-shared/src/schema/admin';
import {
  ContractStatus,
  EmploymentContractType,
  Settlement,
  CalendarDayType,
  AbsenceType,
  OnCallMultiplier,
  BenefitGroupType,
  VatNotAppliedReason,
  HolidayCalendarId,
  CompanyRole,
} from '@virtuslab/nfs-shared/src/schema/user';
import { parsePluralMessageBlocks, parsePluralMessages } from '@virtuslab/nfs-shared/src/services/i18n/messages';

import messages, { enumMessage, pluralBuildingBlocks, pluralMessages } from '../messages';

import type { DefaultLanguage } from '.';

const stringToObject = <T extends string>(arg: T) => set<Record<T, T>>(arg, arg, {});

const translations: DefaultLanguage = {
  ...messages.map(stringToObject).reduce(merge),
  ...parsePluralMessages(pluralMessages),
  ...parsePluralMessageBlocks(pluralBuildingBlocks),
  [enumMessage('employmentContractType', EmploymentContractType.UOP)]: 'UOP',
  [enumMessage('employmentContractType', EmploymentContractType.UOD)]: 'UOD',
  [enumMessage('employmentContractType', EmploymentContractType.UZ)]: 'UZ',
  [enumMessage('employmentContractType', EmploymentContractType.MANAGEMENT)]: 'MC',
  [enumMessage('settlement', Settlement.DAILY)]: 'Daily',
  [enumMessage('settlement', Settlement.HOURLY)]: 'Hourly',
  [enumMessage('settlement', Settlement.MONTHLY)]: 'Monthly',
  [enumMessage('contractStatus', ContractStatus.CONCLUDED)]: 'Concluded',
  [enumMessage('contractStatus', ContractStatus.TERMINATED)]: 'Terminated',
  [enumMessage('contractStatus', ContractStatus.ACTIVE)]: 'Active',
  [enumMessage('b2bContractType', B2BContractType.B2B)]: 'B2B',
  [enumMessage('b2bContractType', B2BContractType.SUBCONTRACT)]: 'Sub',
  [enumMessage('calendarDayType', CalendarDayType.HOLIDAY)]: 'Public holiday',
  [enumMessage('calendarDayType', CalendarDayType.FREEDAYFOROTHERDAY)]: 'Virtuslab holiday',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.SICKLEAVE)]: 'Sick leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.OUTOFOFFICE)]: 'Out of office',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.ANNUALLEAVE)]: 'Annual leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.UNPAIDLEAVE)]: 'Unpaid leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.SPECIALLEAVE)]: 'Special leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.FLEXTIMELEAVE)]: 'Flextime leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.MATERNITYLEAVE)]: 'Maternity leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.PAIDLEAVE)]: 'Paid leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.DUETOFORCEMAJEURELEAVE)]: 'Unexpected event leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.CHILDCARELEAVE)]: 'Child care leave',
  [enumMessage('absenceTypeInput', AbsenceTypeInput.CAREGIVINGLEAVE)]: 'Caregiving leave',
  [enumMessage('absenceType', AbsenceType.SICKLEAVE)]: 'Sick leave',
  [enumMessage('absenceType', AbsenceType.OUTOFOFFICE)]: 'Out of office',
  [enumMessage('absenceType', AbsenceType.ANNUALLEAVE)]: 'Annual leave',
  [enumMessage('absenceType', AbsenceType.UNPAIDLEAVE)]: 'Unpaid leave',
  [enumMessage('absenceType', AbsenceType.SPECIALLEAVE)]: 'Special leave',
  [enumMessage('absenceType', AbsenceType.FLEXTIMELEAVE)]: 'Flextime leave',
  [enumMessage('absenceType', AbsenceType.MATERNITYLEAVE)]: 'Maternity leave',
  [enumMessage('absenceType', AbsenceType.PAIDLEAVE)]: 'Paid leave',
  [enumMessage('absenceType', AbsenceType.DUETOFORCEMAJEURELEAVE)]: 'Unexpected event leave',
  [enumMessage('absenceType', AbsenceType.CHILDCARELEAVE)]: 'Child care leave',
  [enumMessage('absenceType', AbsenceType.CAREGIVINGLEAVE)]: 'Caregiving leave',
  [enumMessage('onCallMultiplier', OnCallMultiplier.ONE)]: 'x1',
  [enumMessage('onCallMultiplier', OnCallMultiplier.ONEANDHALF)]: 'x1.5',
  [enumMessage('onCallMultiplier', OnCallMultiplier.THREE)]: 'x3',
  [enumMessage('benefitGroupType', BenefitGroupType.HEALTH)]: 'Health',
  [enumMessage('benefitGroupType', BenefitGroupType.OFFICE)]: 'Office',
  [enumMessage('benefitGroupType', BenefitGroupType.SPORT)]: 'Sport',
  [enumMessage('benefitBuyerType', BenefitBuyerType.PROMISEE)]: 'Promisee',
  [enumMessage('benefitBuyerType', BenefitBuyerType.PROMISOR)]: 'Promisor',
  [enumMessage('benefitBuyerType', BenefitBuyerType.THIRDPARTY)]: '3rd party',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.ONE)]: '1',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.TWO)]: '2',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.THREE)]: '3',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.FOUR)]: '4',
  [enumMessage('benefitAdditionalPeople', AdditionalPeopleNumber.MORE)]: 'more than 4',
  [enumMessage('bonusType', BonusType.APPRECIATION)]: 'Appreciation bonus',
  [enumMessage('bonusType', BonusType.EMPLOYEEREFERRAL)]: 'Employee referral',
  [enumMessage('bonusType', BonusType.LANGUAGELEARNING)]: 'Language learning',
  [enumMessage('bonusType', BonusType.OTHER)]: 'Other',
  [enumMessage('bonusType', BonusType.TRAININGPACKAGE)]: 'Training package',
  [enumMessage('bonusType', BonusType.REMOTEWORKEQUIPMENT)]: 'Remote work equipment',
  [enumMessage('bonusType', BonusType.BUSINESSTRAVEL)]: 'Business travel',
  [enumMessage('vatNotAppliedReason', VatNotAppliedReason.EXEMPTFROMVAT)]: 'Exempt from VAT',
  [enumMessage('vatNotAppliedReason', VatNotAppliedReason.VATNOTAPPLICABLE)]: 'VAT not applicable',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.PL)]: 'Poland',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.SE)]: 'Sweden',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.DE_BW)]: 'Germany - Bavaria',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.DE_HE)]: 'Germany - Hessen',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.CH_ZURYCH)]: 'Switzerland',
  [enumMessage('holidayCalendaryId', HolidayCalendarId.UK_ENG)]: 'United kingdom',
  [enumMessage('companyRole', CompanyRole.CHIEFOFFICER)]: 'C-level',
  [enumMessage('companyRole', CompanyRole.DIRECTOR)]: 'Director',
  [enumMessage('companyRole', CompanyRole.HEAD)]: 'Head of',
  [enumMessage('companyRole', CompanyRole.MANAGER)]: 'Manager',
  [enumMessage('companyRole', CompanyRole.LEAD)]: 'Lead',
  [enumMessage('companyRole', CompanyRole.EMPLOYEE)]: 'Employee',
} as const;

export default translations;
